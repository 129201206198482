<template>
    <div v-if="dialogVisible">
        <el-dialog
            title="座位图"
            :visible.sync="dialogVisible"
            width="90%"
            :before-close="resetform"
        >
            <div class="btnbox">
                <el-button type="primary" size="mini" class="font14" @click="submit"
                >保存修改
                </el-button
                >
                <el-button
                    type="primary"
                    size="mini"
                    class="font14"
                    @click="dialogVisible = false"
                >关闭
                </el-button
                >
            </div>
            <div style="position: relative; display: flex;justify-content: center;">
                <!-- style="position: absolute; left: 50%" -->

                <div
                    class="addbox"
                    style="max-width: 90%; overflow: auto; display: flex; width: unset;margin-right: 10px;"
                >




                    <div style="display: flex;flex-direction: column">
                        <!-- ====================================================主席台座位开始 -->
                        <div style="display: flex">
                            <div style="padding-top: 10px;">
                                <div
                                    class="inset"
                                    v-for="(item, index) in zxset"
                                    :key="item.id"
                                    style="
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 30px;
                                    border: none;
                                  "
                                >
                                    第{{ item[0].spai }}排
                                </div>
                            </div>
                            <!-- width: 1350px;  -->
                            <div class="left_info" style="max-width: 100%; margin: 0 auto">
                                <div
                                    style="
                                    margin-top: 20px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    position: relative;
                                    max-width: 100%;
                                    padding: 0 30px;"
                                >
                                    <div
                                        v-for="(item, index) in zxset"
                                        :key="index"
                                        style="display: flex; align-items: center; margin-bottom: 10px"
                                    >
                                        <div
                                            class="innerbox"
                                            v-for="(item2, index2) in item"
                                            :key="index2"
                                            style="
                                            display: flex;
                                            align-items: center;
                                            flex-direction: column;
                                          "
                                        >
                                            <div
                                                v-if="item2.status == 1"
                                                :style="{ marginRight: '10px' }"
                                            >
                                                <!-- 自定义列{{ item2.slie }}-->
                                                <!-- 未自定义列{{ index + 1 + "-" + (item2.idx + 1) }}-->
                                                {{
                                                    item2 && item2.slie ? item2.slie : (index + 1 + "-" + (item2 ? (item2.idx + 1) : ''))
                                                }}
                                            </div>

                                            <div
                                                :style="item2.status == 1 ? '' : 'opacity: 0;'"
                                                class="inset"
                                                :id="index + ',' + index2"
                                                :draggable="item2.name ? true : false"
                                                @dragstart="onZxDragStartset(index + ',' + index2, $event)"
                                                @dragover.prevent
                                                @drop="onZxDrop($event)"
                                                data-area="zxset"
                                            >
                                                {{ item2.name }}
                                                <i
                                                    v-show="item2.name"
                                                    class="el-icon-delete deleteicon"
                                                    @click="deletezxzw(item2, index + ',' + index2)"
                                                ></i>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ====================================================主席台座位结束 -->
                        <div class="zxt_im">主席台</div>
                        <!--                    ==========================================================-->
                        <!-- ====================================================普通座位开始 -->
                        <div style="display: flex">
                            <div style="padding-top: 10px;">
                                <div
                                    class="inset"
                                    v-for="(item, index) in list"
                                    :key="item.id"
                                    style="
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 30px;
                                    border: none;
                                  "
                                >
                                    第<span style="writing-mode: horizontal-tb">{{ index + 1 }}</span>排
                                </div>
                            </div>
                            <!-- width: 1350px;  -->
                            <div class="left_info" style="max-width: 100%; margin: 0 auto">
                                <div
                                    style="
                                    margin-top: 20px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    position: relative;
                                    max-width: 100%;
                                    padding: 0 30px;"
                                >
                                    <div
                                        v-for="(item, index) in list"
                                        :key="index"
                                        style="display: flex; align-items: center; margin-bottom: 10px"
                                    >
                                        <div
                                            class="innerbox"
                                            v-for="(item2, index2) in item"
                                            :key="index2"
                                            style="
                                            display: flex;
                                            align-items: center;
                                            flex-direction: column;
                                          "
                                        >
                                            <div
                                                v-if="item2.status == 1"
                                                :style="{ marginRight: '10px' }"
                                            >
                                                <!-- 自定义列{{ item2.slie }}-->
                                                <!-- 未自定义列{{ index + 1 + "-" + (item2.idx + 1) }}-->
                                                {{
                                                    item2 && item2.slie ? item2.slie : (index + 1 + "-" + (item2 ? (item2.idx + 1) : ''))
                                                }}
                                            </div>

                                            <div
                                                :style="item2.status == 1 ? '' : 'opacity: 0;'"
                                                class="inset"
                                                :id="index + ',' + index2"
                                                :draggable="item2.name ? true : false"
                                                @dragstart="onDragStartset(index + ',' + index2, $event)"
                                                @dragover.prevent
                                                @drop="onDrop($event)"
                                                data-area="list"
                                            >
                                                {{ item2.name }}
                                                <i
                                                    v-show="item2.name"
                                                    class="el-icon-delete deleteicon"
                                                    @click="deletezw(item2, index + ',' + index2)"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ====================================================普通座位结束 -->
                    </div>






                </div>

                <!-- ====================================================右侧人员列表 -->
                <div style="padding-top: 10px;">
                    <div
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          font-weight: bold;
                          color: #d00;
                          margin-bottom: 10px;
                        "
                    >
                        待定人员列表
                    </div>
					<el-input placeholder="请输入名称" v-model="searchname" size="mini" style="margin-bottom: 10px;">
					    
					</el-input>
                    <div style="text-align: center; max-height: 55vh; overflow-y: auto">
                        <div
                            v-for="(item, index) in userlist"
							v-if="filtername(item.name)"
                            :key="index"
                            style="
                            border: 1px solid #ccc;
                            border-radius: 10px;
                            padding: 5px 10px;
                            margin-bottom: 10px;
                            cursor: pointer;
                          "
                            :draggable="true"
                            @dragstart="onDragStart(index, $event)"
                        >
                            {{ item.name }}
                        </div>
                        <div
                            v-if="userlist.length <= 0"
                            style="
                padding: 5px 10px;
                margin-bottom: 10px;
                cursor: pointer;
                color: #666;
              "
                        >
                            已无数据~
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    data() {
        return {
			searchname:'',
            dialogVisible: false,
            metting_id: "",
            list: [],
            draggingIndex: 0,
            userlist: [],
            yuanindex: "", //座位互拖-原始index
            yuan_area: '', //座位互拖-原始区域
            temp_item: {}, //主席台-座位互拖-临时存储

            zxset: [],
        };
    },
    created() {
    },
    methods: {
		filtername(name){
			return !this.searchname?true:name.includes(this.searchname)
		},
        resetform() {
            this.dialogVisible = false
        },
        submit() {
            // this.zxset = this.zxset.reverse(); //反转一下，不然保存的时候，顺序会反的
            let zhu = JSON.parse(JSON.stringify(this.zxset)).reverse();
            // console.log('座位',this.list);
            // console.log('主席台',this.zxset)
            this.$post({
                url: "/api/login/hyzcbc2hui",
                params: {
                    wymeeting_id: this.metting_id,
                    list: JSON.stringify(this.list),
                    zhu: JSON.stringify(zhu),
                },
            }).then((res) => {
                this.$message.success("保存成功");
                this.dialogVisible = false;
            });
        },
        init() {
            //无数据时，初始化
            this.list = [];
            // 第一组 18 条数据
            var group1 = [];
            for (var i = 1; i <= 17; i++) {
                group1.push({user_id: "", name: ""});
            }
            this.list.push(group1);

            // 后六组每组 20 条数据
            for (var j = 2; j <= 7; j++) {
                var group = [];
                for (var k = 1; k <= 20; k++) {
                    group.push({user_id: "", name: ""});
                }
                this.list.push(group);
            }
            this.zxset = [
                {user_id: '', name: ''},
                {user_id: '', name: ''},
                {user_id: '', name: ''},
                {user_id: '', name: ''},
                {user_id: '', name: ''},
                {user_id: '', name: ''},
                {user_id: '', name: ''}
            ]
        },
        // 删除普通座位
        deletezw(item, index) {
            let itemstr = JSON.parse(JSON.stringify(item));
            console.log(this.userlist);
            this.$set(this.userlist, this.userlist.length, itemstr);
            let setindex = index.split(","); //目标座位
            this.list[setindex[0]][setindex[1]].name = "";
            this.list[setindex[0]][setindex[1]].user_id = "";
        },
        // 删除主席台座位
        deletezxzw(item, index) {
            let itemstr = JSON.parse(JSON.stringify(item));
            this.$set(this.userlist, this.userlist.length, itemstr);
            let setindex = index.split(","); //目标座位
            this.zxset[setindex[0]][setindex[1]].name = "";
            this.zxset[setindex[0]][setindex[1]].user_id = "";
        },
        //人员列表拖动开始
        onDragStart(index, event) {
            this.yuanindex = "";
            this.draggingIndex = index;
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.setData("text/plain", index);
        },
        //座位表拖动开始
        onDragStartset(index, event) {
            this.yuanindex = index.split(",");
            this.yuan_area = event.target.getAttribute('data-area');
            this.temp_item = this.list[this.yuanindex[0]][this.yuanindex[1]]; //存储原始数据，用于与不同区域的数据交换
        },
        //座位表拖动结束
        onDrop(event) {
            event.stopPropagation();
            event.preventDefault();

            let listindex = event.target.id.split(","); //目标座位
            let normal_temp_item = JSON.parse(JSON.stringify(this.list[listindex[0]][listindex[1]])); //普通座位有人的话，存
            let target_area = event.target.getAttribute('data-area');

            // 相同区域
            if (this.yuanindex && this.yuan_area == target_area) {
                let mid = this.list[this.yuanindex[0]][this.yuanindex[1]].user_id;
                let midname = this.list[this.yuanindex[0]][this.yuanindex[1]].name || "";
                let miduser = this.list[this.yuanindex[0]][this.yuanindex[1]].user || "";
                // 只换名字，不换位置编号, status为'2'--不可用座位
                if (this.list[listindex[0]][listindex[1]].status == "2") {
                    return;
                }
                //region
                this.$set(
                    this.list[this.yuanindex[0]][this.yuanindex[1]],
                    "name",
                    this.list[listindex[0]][listindex[1]].name ? this.list[listindex[0]][listindex[1]].name : ""
                );
                this.$set(
                    this.list[listindex[0]][listindex[1]],
                    "name",
                    midname
                );
                this.$set(
                    this.list[this.yuanindex[0]][this.yuanindex[1]],
                    "user_id",
                    this.list[listindex[0]][listindex[1]].user_id
                );
                this.$set(
                    this.list[listindex[0]][listindex[1]],
                    "user_id",
                    mid
                );
                this.$set(
                    this.list[this.yuanindex[0]][this.yuanindex[1]],
                    "user",
                    this.list[listindex[0]][listindex[1]].user
                );
                this.$set(
                    this.list[listindex[0]][listindex[1]],
                    "user",
                    miduser
                );
                //endregion
                return;

            // 不同区域
            }else if(this.yuanindex && this.yuan_area != target_area){
                let item = JSON.parse(JSON.stringify(this.zxset[this.yuanindex[0]][this.yuanindex[1]]));
                let index = event.target.id;
                this.deletezxzw(item, index);
                let dragindex = this.userlist.length-1;

                // 目标有人-交换
                if(this.list[listindex[0]][listindex[1]].name){
                    this.list[listindex[0]][listindex[1]].name = this.temp_item.name;
                    this.list[listindex[0]][listindex[1]].user_id = this.temp_item.user_id;
                    this.list[listindex[0]][listindex[1]].user = this.temp_item.user;
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]].name = normal_temp_item.name;
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]].user_id = normal_temp_item.user_id;
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]].user = normal_temp_item.user;
                    this.userlist.splice(dragindex, 1);
                    this.temp_item = {};
                    return;
                }else{
                    // 直接拖动
                    this.list[listindex[0]][listindex[1]].name = this.zxset[this.yuanindex[0]][this.yuanindex[1]].name;
                    this.list[listindex[0]][listindex[1]].user_id = this.zxset[this.yuanindex[0]][this.yuanindex[1]].user_id;
                    this.list[listindex[0]][listindex[1]].user = this.zxset[this.yuanindex[0]][this.yuanindex[1]].user;
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]].name = "";
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]].user_id = "";
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]].user = "";
                    this.userlist.splice(dragindex,1);
                    return;
                }
            }else{
                //用户列表拖拽入座
                if (this.list[listindex[0]][listindex[1]].status == 2) return;
                this.list[listindex[0]][listindex[1]].name = this.userlist[this.draggingIndex].name;
                this.list[listindex[0]][listindex[1]].user_id = this.userlist[this.draggingIndex].user_id;
                this.userlist.splice(this.draggingIndex, 1);
            }
        },

        //主席台拖动开始
        onZxDragStartset(index, event) {
            this.yuanindex = index.split(",");
            this.yuan_area = event.target.getAttribute('data-area');
            this.temp_item = JSON.parse(JSON.stringify(this.zxset[this.yuanindex[0]][this.yuanindex[1]])); // 主席台开始拖动的座位信息
        },
        //主席台拖动结束
        onZxDrop(event) {
            event.stopPropagation();
            event.preventDefault();

            let listindex = event.target.id.split(","); //目标座位
            let zx_temp_item = JSON.parse(JSON.stringify(this.zxset[listindex[0]][listindex[1]])); //主席台有人的话，存，用于与普通座位交换
            let target_area = event.target.getAttribute('data-area');

            if (this.yuanindex && this.yuan_area == target_area) {
                // //座位表互拖
                console.log('目标', this.zxset[listindex[0]][listindex[1]]);
                console.log('来源', this.zxset[this.yuanindex[0]][this.yuanindex[1]]);
                //判断目标的sfzhu是否为1：主席台
                //。。。。。

                let mid = this.zxset[this.yuanindex[0]][this.yuanindex[1]].user_id;
                let midname = this.zxset[this.yuanindex[0]][this.yuanindex[1]].name || "";
                let miduser = this.zxset[this.yuanindex[0]][this.yuanindex[1]].user || "";
                // 只换名字，不换位置编号
                // 如果目标座位的status为'2'--不可用座位
                if (this.zxset[listindex[0]][listindex[1]].status == "2") {
                    return;
                }
                //region
                this.$set(
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]],
                    "name",
                    this.zxset[listindex[0]][listindex[1]].name ? this.zxset[listindex[0]][listindex[1]].name : ""
                );
                this.$set(
                    this.zxset[listindex[0]][listindex[1]],
                    "name",
                    midname
                );
                this.$set(
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]],
                    "user_id",
                    this.zxset[listindex[0]][listindex[1]].user_id
                );
                this.$set(
                    this.zxset[listindex[0]][listindex[1]],
                    "user_id",
                    mid
                );
                // username
                this.$set(
                    this.zxset[this.yuanindex[0]][this.yuanindex[1]],
                    "user",
                    this.zxset[listindex[0]][listindex[1]].user
                );
                this.$set(
                    this.zxset[listindex[0]][listindex[1]],
                    "user",
                    miduser
                );
                //endregion
                return;
            }else if(this.yuanindex && this.yuan_area != target_area){
                //获取拖拽的item对象
                let item = JSON.parse(JSON.stringify(this.list[this.yuanindex[0]][this.yuanindex[1]]));

                let index = event.target.id;
                this.deletezw(item,index); // 先放回用户列表
                let dragindex = this.userlist.length-1;
                // 目标有人-交换
                if(this.zxset[listindex[0]][listindex[1]].name){
                    this.zxset[listindex[0]][listindex[1]].name = this.temp_item.name;
                    this.zxset[listindex[0]][listindex[1]].user_id = this.temp_item.user_id;
                    this.zxset[listindex[0]][listindex[1]].user = this.temp_item.user;
                    this.list[this.yuanindex[0]][this.yuanindex[1]].name = zx_temp_item.name;
                    this.list[this.yuanindex[0]][this.yuanindex[1]].user_id = zx_temp_item.user_id;
                    this.list[this.yuanindex[0]][this.yuanindex[1]].user = zx_temp_item.user;
                    this.userlist.splice(dragindex,1); // 再从用户列表删除
                    this.temp_item = {};
                    return;
                }else{
                    // 目标没人-直接拖动
                    this.zxset[listindex[0]][listindex[1]].name = this.list[this.yuanindex[0]][this.yuanindex[1]].name;
                    this.zxset[listindex[0]][listindex[1]].user_id = this.list[this.yuanindex[0]][this.yuanindex[1]].user_id;
                    this.zxset[listindex[0]][listindex[1]].user = this.list[this.yuanindex[0]][this.yuanindex[1]].user;
                    this.list[this.yuanindex[0]][this.yuanindex[1]].name = "";
                    this.list[this.yuanindex[0]][this.yuanindex[1]].user_id = "";
                    this.list[this.yuanindex[0]][this.yuanindex[1]].user = "";
                    this.userlist.splice(dragindex,1);
                    return;
                }

            }else {
                //用户列表拖拽入座
                if (this.zxset[listindex[0]][listindex[1]].status == 2) return;
                this.zxset[listindex[0]][listindex[1]].name = this.userlist[this.draggingIndex].name;
                this.zxset[listindex[0]][listindex[1]].user_id = this.userlist[this.draggingIndex].user_id;
                this.userlist.splice(this.draggingIndex, 1);
            }
        },


        setnumber(index, length) {
            //显示座位下的序号
            let jis = [];
            let ous = [];
            for (let i = 1; i <= length; i++) {
                if (i % 2 == 0) {
                    ous.push(i);
                } else {
                    jis.push(i);
                }
            }
            jis.sort((a, b) => b - a);
            let list = jis.concat(ous);
            return list[index];
        },
    },
};
</script>

<style lang="scss" scoped>
.inset {
    width: 50px;
    height: 80px;
    border: 1px solid #333;
    padding: 5px;
    // border-right: none;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;
    text-orientation: upright;
    cursor: pointer;
    position: relative;
    line-height: 20px;
    border-radius: 5px;
    margin-right: 10px;

    .deleteicon {
        position: absolute;
        right: 0;
        top: 0;
        color: #d00;
        display: none;
    }
}

.inset:hover {
    .deleteicon {
        display: block;
    }
}

.innerbox:nth-last-child(1) {
    .inset {
        // border-right: 1px solid #333;
    }
}
.innerbox{
    height: 100px;
}

.zuowei_pai {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.zuowei_lie {
    padding: 5px 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.zuowei_lie:nth-last-child(1) {
    margin-right: 0;
}

.zxt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding-left: 40px;
}

.zxt_im {
    background-color: rgb(180, 180, 180);
    width: 90%;
    padding: 3px 0;
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
}

.zxt_item {
    padding: 5px 10px;
    margin-right: 15px;
}

.zxt_item:nth-last-child(1) {
    margin-right: 0;
}

.left_info {
    display: flex;
    align-items: center;
    //   justify-content: center;
    flex-direction: column;
}

.item_line {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .tlename {
        width: 120px;
        font-size: 14px;

        text-align: right;
        color: #333;
    }

    .bticon {
        width: 10px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 34px;
        font-size: 26px;
        color: red;
    }
}

.infobox {
    width: 100%;
    padding-left: 50px;
    padding-right: 30px;

    .contbox {
        display: flex;
        padding: 20px 40px;
        height: 750px;
        transition: all 0.5s;
        overflow: hidden;
    }

    .colosecont {
        height: 0;
        padding: 0;
    }

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 20px;
        color: #ec4a4a;
        border-bottom: 1px solid #ec4a4a;
    }
}

.addbox {
    height: 80vh;
    width: 100%;
    padding: 30px;
    background-color: #fff;
}

.add_cont {
    width: 100%;
    background-color: #fff;
    padding: 10px;
}

.btnbox {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

::v-deep .el-form-item__error {
    padding-top: 0;
}

::v-deep .el-form-item {
    margin-bottom: 10px;
}

::v-deep .el-form-item__label {
    color: #999;
    font-size: 14px;
}

::v-deep
.el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:before {
    content: "" !important;
}

::v-deep .el-input--small .el-input__icon {
    color: #333;
    font-weight: bold;
}

::v-deep .el-dialog {
    margin: 0 !important;
}

::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
}

::v-deep .el-dialog__body {
    padding: 10px;
    padding-top: 0px;
}

::v-deep .el-tabs--border-card {
    height: 100%;
}

::v-deep .el-tabs--border-card {
    box-shadow: 0 0 0 #fff;
}

::v-deep .el-tabs__header {
    background-color: #fff;
}
</style>
